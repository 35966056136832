import axiosInstance from "../helpers/axiosInstance";
export const fetchOrders = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/wms?account_type=CUSTOMER_ACCOUNT`, { params })
    return res.data
}

export const fetchPendingOrders = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/wms/pending?account_type=CUSTOMER_ACCOUNT`, { params })
    return res.data
}

export const fetchProcessingOrders = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/wms/processing`, { params })
    return res.data
}

export const fetchShippedOrders = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/wms/shipped`, { params })
    return res.data
}

export const fetchShippedOrdersSummary = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/wms/summary/shipped`, { params })
    return res.data
}

export const fetchPickAndShipOrders = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/pick_n_ship/wms`, { params })
    return res.data
}

export const fetchOrder = async (orderId) => {
    if(orderId===undefined){
        return null
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/wms/${orderId}`)
    return res.data
}

export const getPickingList = async (pickingOrderId) => {
    if(pickingOrderId===undefined){
        return null
    }
  
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/picking-orders/${pickingOrderId}/picking_list`, {responseType: 'blob'});
    
    const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
  
    // Open the PDF in a new tab
    window.open(pdfUrl);
}  

/**
 * @param order {{shippingMethod: {id: string, name: string}, shippingDate: string, numberShipping: string, orderNumber: string, referenceName: string}}
 * @param shippingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 * @param billingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 */
const getOrderData = (order, shippingAddress, billingAddress) => {
    const data = {
        shipping_method: order.shippingMethod.id,
        shipping_date: order.shippingDate,
        shipping: {
            first_name: shippingAddress.firstName,
            last_name: shippingAddress.lastName,
            phone: shippingAddress.phone,
            address1: shippingAddress.address,
            country: shippingAddress.country.name,
            city: shippingAddress.city,
            province: shippingAddress.province,
            zipCode: shippingAddress.zipCode
        },

        billing: {
            first_name: billingAddress.firstName,
            last_name: billingAddress.lastName,
            phone: billingAddress.phone,
            address1: billingAddress.address,
            country: billingAddress.country.name,
            city: billingAddress.city,
            province: billingAddress.province,
            zipCode: billingAddress.zipCode
        },
    }
    return data
}

/**
 *
 * @param order {{shippingMethod: {id: string, name: string}, shippingDate: string, numberShipping: string, orderNumber: string, referenceName: string}}
 * @param products {[{id: integer, name: string, quantity: int, type: string}]}
 * @param shippingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 * @param billingAddress {{status: string, firstName: string, lastName: string, phone: string, address: string, country: {id: string, name: string}, city: string, province: string, zipCode: string}}
 * @param storeId {integer}
 * @returns {Promise<void>}
 */
export const updateOrder = async (
    id,
    order,
    shippingAddress,
    billingAddress,
    products= []
) => {
    const data = {
        ...getOrderData(order, shippingAddress, billingAddress),
        order_lines: products
    }
    const res = await axiosInstance.put(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${id}`, data)
    return res.data
}

export const pickAllOrderStocks = async (orderId) => { 
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${orderId}/pick-all-stock`)
    return res.data
}

export const putPickingOrderInReview = async (order_id, products) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/picking-orders/${order_id}/put-in-review`, { products })
    return res.data
}

export const bulkPickOrderStock = async function(orderId) {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/picked`, { order_id: orderId })
    return res.data
}

export const createShippingIncidents = async (order_id, category) => {
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping-incidents/create`, 
        { order_id: order_id, category: category  })
    return res.data
}

export const reviewShippingIncidents = async (incident_id) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping-incidents/review`, 
        { incident_id: incident_id })
    return res.data
}

export const resolveShippingIncidents = async (incident_id, resolution) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping-incidents/resolve`, 
        { incident_id: incident_id, resolution: resolution })
    return res.data
}

export const resetShippingIncidents = async (incident_id) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/shipping-incidents/reset`, 
        { incident_id: incident_id })
    return res.data
}

export const updateShippingStatus = async (order_id, status, description) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/update_shipping_status`, 
        { order_id: order_id, shipping_status: status, description: description })
    return res.data
}

export const getShippingGroupCostsForPackage = async (order_id, shipping_package_ids) => {
    let params = {
        shipping_package_ids: shipping_package_ids
        // forced_weight_estimate
    }
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/${order_id}/shipping-group-costs`, { params })
    return res.data
}

export const findOrderReturn = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/wms/find-order-return`, { params })
    return res.data
}

export const OrderReassingCarrierBulk = async (params) => {
    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/orders/reassign-carrier-bulk`, params)
    return res.data
}

export const fetchOrderReassingCarrierBulkTracker = async function (batch_id) {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1//user-async-tasks/track_batch/${batch_id}`)
    return res.data
}