import { useMemo, useRef, useState } from "react";

import { SHIPPING_TYPES } from './constants';
import { findMultiPackageShippingMethod, findShippingMethod } from './utils';
import PackageQuantitySelect from './PackageQuantitySelect';
import PackageSelect from './PackageSelect';
import ShippingMethodSelect from "./ShippingMethodSelect";
import { PACKAGE_OPTIONS } from "../RecommendedPackageSection/constants";
import { getShippingGroupCostsForPackage } from "../../../../../services/orderServices";

const ShippingPackageDataSection = ({
        onPackageNumberChange = (newValue) => {},
        onShippingMethodChange = (newValue) => {},
        onShippingPackageChange = (newValue) => {},
        initialPackageQuantity = 1,
        initialShippingMethod,
        availableShippingMethods,
        availableShippingPackages,
        storePackingOptions,
        orderWarehouseId,
        orderStoreId = null,
        isShippingMethodEditable = false,
        shippingMethodOptions,
        isPackagelessOrder,
        hasPackagelessProduct,
        isFragileOrder,
        disableMultiPackageAutoSelect = false,
        orderCanRecalculateShippingMethod = true,
        orderId = null,
        onBusyChange = (isBusy) => {},
    }) => {
    const [shippingMethod, setShippingMethod] = useState(initialShippingMethod)
    const [shippingPackages, setShippingPackages] = useState([null])
    const [isBusy, setIsBusy] = useState(false)
    const recalculatedShippingMethod = useRef(initialShippingMethod)

    const multiPackageShippingMethod = useMemo(() => {
        const multiPackageMethods = findMultiPackageShippingMethod(orderWarehouseId)

        if(!multiPackageMethods || multiPackageMethods?.length <= 0 || shippingPackages.length <= 1){
            return null
        }

        return findShippingMethod(availableShippingMethods, multiPackageMethods?.[0], orderStoreId)
    },[availableShippingMethods, orderWarehouseId])

    const handlePackageQuantityChange = (newPackageQuantity) => {
        const updatedShippingPackages = shippingPackages.map(shippingPackage => ({...shippingPackage}))

        while(updatedShippingPackages.length < newPackageQuantity){
            updatedShippingPackages.push(null)
        }

        while(updatedShippingPackages.length > newPackageQuantity){
            updatedShippingPackages.pop()
        }

        setShippingPackages(updatedShippingPackages)

        // Automatic multi package select is only enable if the shipping method is not editable and the initial method is not pickup
        if(!disableMultiPackageAutoSelect && !isShippingMethodEditable && initialShippingMethod.shipping_type !== SHIPPING_TYPES.PICKUP && multiPackageShippingMethod){
            const newShippingMethod = newPackageQuantity > 1 ? multiPackageShippingMethod : recalculatedShippingMethod.current
            setShippingMethod(newShippingMethod)
            onShippingMethodChange(newShippingMethod, newPackageQuantity, updatedShippingPackages)
        } else {
            onPackageNumberChange(newPackageQuantity, updatedShippingPackages)
        }
    }

    const handleOnShippingPackageChange = (updatedShippingPackage, packageIndex) => {
        const shippingPackage = {...updatedShippingPackage}

        if(updatedShippingPackage.package_type === PACKAGE_OPTIONS.PACKAGELESS){
            shippingPackage.length_cm = null
            shippingPackage.width_cm = null
            shippingPackage.height_cm = null
            shippingPackage.weight_kg = null
            shippingPackage.volumetric_weight = null
        }

        const updatedShippingPackages = shippingPackages.map((currentShippingPackage, index) => (
            packageIndex === index ? shippingPackage : {...currentShippingPackage}
            ))
        
        const selectedShippingPackage = updatedShippingPackages.filter(sp => sp.id != null)
        setShippingPackages(updatedShippingPackages)
        onShippingPackageChange(updatedShippingPackages)

        if(updatedShippingPackage.package_type != PACKAGE_OPTIONS.PACKAGELESS && selectedShippingPackage.length == updatedShippingPackages.length ) { // && updatedShippingPackages.length == 1
            const packageWithBiggestVolumetric = updatedShippingPackages.reduce((prev, current) => (prev.volumetric_weight > current.volumetric_weight) ? prev : current)
            recalculateShippingMethod(packageWithBiggestVolumetric, updatedShippingPackages)
        }
    }

    const handleUpdatePackagelessPackage = (similarShippingPackage, packagelessPackageIndex) => {
        const packagelessPackage = {...shippingPackages[packagelessPackageIndex]}

        packagelessPackage.length_cm = similarShippingPackage.length_cm
        packagelessPackage.width_cm = similarShippingPackage.width_cm
        packagelessPackage.height_cm = similarShippingPackage.height_cm
        packagelessPackage.weight_kg = similarShippingPackage.weight_kg
        packagelessPackage.volumetric_weight = similarShippingPackage.volumetric_weight

        const updatedShippingPackages = shippingPackages.map((currentShippingPackage, index) => (
            packagelessPackageIndex === index ? packagelessPackage : {...currentShippingPackage}
        ))

        setShippingPackages(updatedShippingPackages)
        onShippingPackageChange(updatedShippingPackages)

        // const packageWithBiggestVolumetric = updatedShippingPackages.reduce((prev, current) => (prev.volumetric_weight > current.volumetric_weight) ? prev : current)
        recalculateShippingMethod(similarShippingPackage)
    }

    const setBusy = (newIsBusy) => {
        setIsBusy(newIsBusy)
        onBusyChange(newIsBusy)
    }

    const recalculateShippingMethod = async (shippingPackage, updatedShippingPackages) => {
        if (!orderId || !orderCanRecalculateShippingMethod ) return
        if (shippingMethod.shipping_type === SHIPPING_TYPES.PICKUP) return
        setBusy(true)

        let shipping_package_ids = []
        if (updatedShippingPackages?.length > 1){
            shipping_package_ids = updatedShippingPackages.map(sp => sp.id)
        }else{
            shipping_package_ids = [shippingPackage.id]
        }

        try {
            const newShippingMethod = await getBestShippingMethodForPackage(shipping_package_ids)

            if (!newShippingMethod) {
                // console.log("No shipping method returned by getBestShippingMethodForPackage")
                setBusy(false)
                return
            }
            if (newShippingMethod.id === shippingMethod.id) {
                // console.log("No need to update shipping method")
                setBusy(false)
                return
            }
            recalculatedShippingMethod.current = newShippingMethod
            setShippingMethod(newShippingMethod)
            onShippingMethodChange(newShippingMethod, updatedShippingPackages.length, updatedShippingPackages)
        } catch (error) {
            console.error("Error recalculateShippingMethod", error)
        }
        setBusy(false)
    }

    const getBestShippingMethodForPackage = async (shippingPackageIds) => {
        
        try {
            const response = await getShippingGroupCostsForPackage(orderId, shippingPackageIds)
            if (response.shipping_costs.length === 0) return null
            const bestShippingCost = response.shipping_costs[0]
            const bestShippingMethodId = bestShippingCost.shipping_method_id

            const newShippingMethod = availableShippingMethods.find(shippingMethod => shippingMethod.id === bestShippingMethodId)
            if (!newShippingMethod) {
                console.error(`Shipping method with id ${bestShippingMethodId} not found`)
                return null
            }

            return newShippingMethod

        } catch (error) {
            console.log("shipping costs error response", error.response)

            if (error.response?.status === 422) {
                let code = error.response.data.error_code
                if (code === "INVALID_PARAMETER") {
                    throw error
                }
                console.error("Error getting shipping costs", error)
            } else {
                throw error
            }
        }
    }

    return (
        <section className="bg-white rounded-lg">
            <div className="p-4">
                <h2 className="mb-0 text-2xl font-bold">{'Empaque utilizado'}</h2>
                <p className="mb-0 text-lg">{'Registra cómo se enviará el paquete'}</p>
            </div>

            <PackageQuantitySelect
                initialPackageQuantity={initialPackageQuantity}
                onUpdateQuantity={handlePackageQuantityChange}
            />

            {shippingPackages.map((shippingPackage, index) => (
                <div key={`package-option-${index}`} className="border-t p-4">
                    <PackageSelect
                        title={`Empaque ${index + 1}`}
                        isPackagelessOrder={isPackagelessOrder}
                        hasPackagelessProduct={hasPackagelessProduct}
                        isFragileOrder={isFragileOrder}
                        storePackingOptions={storePackingOptions}
                        availableShippingPackages={availableShippingPackages}
                        onShippingPackageChange={(updatedShippingPackage) => handleOnShippingPackageChange(updatedShippingPackage, index)}
                        showVolumetricWeight={shippingPackage?.package_type !== PACKAGE_OPTIONS.PACKAGELESS}
                    />

                    {shippingPackage?.package_type === PACKAGE_OPTIONS.PACKAGELESS && (
                        <div className="pt-4">
                            <div className="text-base font-medium">
                                {"Selecciona un paquete con medida similar"}
                            </div>
                            <PackageSelect
                                ignoreFragileType={true}
                                isFragileOrder={isFragileOrder}
                                storePackingOptions={storePackingOptions}
                                availableShippingPackages={availableShippingPackages}
                                onShippingPackageChange={(updatedShippingPackage) => handleUpdatePackagelessPackage(updatedShippingPackage, index)}
                            />
                        </div>
                    )}
                </div>
            ))}

            <ShippingMethodSelect
                initialShippingMethod={initialShippingMethod}
                shippingMethodOptions={shippingMethodOptions}
                isShippingMethodEditable={isShippingMethodEditable}
                onShippingMethodChange={
                    (sm) => {
                        setShippingMethod(sm)
                        onShippingMethodChange(sm)
                    }
                }
                value={shippingMethod}
                disabled={isBusy}
            />

        </section>
    )
}

export default ShippingPackageDataSection
