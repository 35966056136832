import { PageTopBar, ButtonLoader, DialogView, ConfirmDialog, PageView } from "../../../components";
import {useParams, useHistory, useLocation} from "react-router-dom";
import {useState, useMemo, useRef, useEffect, useContext, useCallback} from "react";
import {Loader} from "../../../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import {LabelView} from "./LabelView";
import {UserContext} from "../../../hooks/UserContext";
import countryList from 'react-select-country-list';
import { getShipingLabels } from "../../../services";
import {useHotkeys} from 'react-hotkeys-hook';
import {GENERATE_LABELS, GENERATE_ORDER_LABEL} from "../../../navigation/constants";
import LogRocket from 'logrocket';
import { fetchSipSiblingByProduct } from "../../../services/pickingOrderServices";
import { SHIPPING_TYPES } from "./sections/ShippingPackageDataSection/constants"; 
import { setRecordingState } from "../../../redux/webcamRecorderSlice";
import { stopRecording } from "../../../redux/webcamRecorderSlice";

import {
    orderShippingAddress,
    setShipmentData,
    setShippingAddress,
    setShippingDetails,
    setData,
    shipmentData,
    ordershippingDetails,
} from "../../../redux/createShipmentSlice";

import moment from 'moment';
import {
    fetchOrder,
    generateLabel,
    fetchWarehouseCubboShippingMethods,
    fetchOrderShippingPackages,
    fetchStoreConfig,
    fetchStorePackingOptions,
    getWarehousePackingMaterials,
    getStorePackingMaterials
} from "../../../services";

import {useQueryClient, useQuery} from "react-query";
import {cloneDeep} from "lodash";
import {ImeiDialog} from "../../../components/ImeiDialog";
import {CorreiosDialog} from "../../../components/CorreiosDialog";
import { PickingOrderToReviewDialog } from "../../../components/PickingOrderToReviewDialog";
import { MissingPickingPiecesSection, RecommendedPackageSection, ShippingPackageDataSection, FragileNotificationSection, InsertsSection, GenerateShipmentFooter, ProductListSection, ShippingLabelsListSection, AttachmentsListSection, ShippingAddressSection, CodNotificationSection, GeneratedLabelNotificationSection, MissingInfoNotificationSection, InternationalNotificationSection } from './sections';
import { validateIsPackagelessOrder, validateHasPackagelessProduct, validateIsFragileOrder } from "../../../utils/orderUtils";
import { validateIsMultiPackageShippingMethod } from "./sections/ShippingPackageDataSection/utils";
import { isProductionEnvironment } from "../../../helpers/environmentHelper";
import { generateRandomId } from "../../../helpers/WebcamHelper";

export const GenerateShipment = () => {

    const queryClient = useQueryClient()
    const shippingAddress = useSelector(orderShippingAddress)
    const shippingDetails = useSelector(ordershippingDetails)
    const shipment = useSelector(shipmentData)
    const {warehouse_id} = useParams()
    const [loadingButtonLoader, setLoadingButtonLoader] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [trackingNumber, setTrackingNumber] = useState(null)
    const [pickingOrder, setPickingOrder] = useState(null)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [openWarningDialog, setOpenWarningDialog] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [shippingLabel, setShippingLabel] = useState({})
    const [printInformation, setPrintInformation] = useState(false)
    const [openDialogDocument, setOpenDialogDocument] = useState(false)
    const [openDialogImei, setOpenDialogImei] = useState(false)
    const [openDialogRevertPacking, setOpenDialogRevertPacking] = useState(false)
    const [openDialogCorreios, setOpenDialogCorreios] = useState(false)
    const [reviewedProductId, setReviewedProductId] = useState(null)
    const [shippingDocuments, setShippingDocuments] = useState([])
    const [extraShippingLabels, setExtraShippingLabels] = useState([])
    const [alert, setAlert] = useState(false)
    const [latestAddedProduct, setLatestAddedProduct] = useState(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const {id} = useParams()
    const {user} = useContext(UserContext)
    const options = useMemo(() => countryList().getData(), [])
    const [productsToReviewById, setProductsToReviewById] = useState({})
    const [loading, setLoading] = useState(false)
    const [busyPackageSelection, setBusyPackageSelection] = useState(false)
    const search_params = useLocation().search
    const inhereted_exclude_picking_order_ids_string = new URLSearchParams(search_params).get('exclude_picking_order_ids')
    const inhereted_exclude_picking_order_ids = inhereted_exclude_picking_order_ids_string ? inhereted_exclude_picking_order_ids_string.split(",") : []
    const inputRef = useRef()

    const{ isLoading, isPreviousData, isFetchedAfterMount, isError, error, data } = useQuery(`order-${id}`, () => fetchOrder(id), {
        onSuccess: (info) => {
            if (isFetchedAfterMount == false) {
                setOrderData(info)
            }

        }
    })

    const {
        isLoading: warehousePackingMaterialsIsLoading,
        isPreviousData: warehousePackingMaterialsIsPreviousData,
        isError: warehousePackingMaterialsIsError,
        error: warehousePackingMaterialsError,
        data: warehousePackingMaterialsData
    } = useQuery(["warehouse-packing-materials", warehouse_id], () => getWarehousePackingMaterials(warehouse_id))

    const {
        isLoading: storePackingMaterialsIsLoading,
        isPreviousData: storePackingMaterialsIsPreviousData,
        isError: storePackingMaterialsIsError,
        error: storePackingMaterialsError,
        data: storePackingMaterialsData
    } = useQuery(["warehouse-packing-materials", warehouse_id, data?.store?.id], () => getStorePackingMaterials(warehouse_id, data?.store?.id), {
        enabled: data ? true : false
    })

    const{
        isLoading: isLoadingImeiValidationRegex,
        isError: isErrorImeiValidationRegex,
        error: ImeiValidationRegexError,
        data: imeiValidationRegex
    } = useQuery(['imei_validation_regex', data?.store?.id], () => fetchStoreConfig(data.store.id, "imei_validation_regex"), {
        enabled: !!data
    })

    const cubboShippingMethods = useQuery('cubbo_shipping', () => fetchWarehouseCubboShippingMethods(warehouse_id))

    const shippingPackages = useQuery(`order_shipping_packages-${id}`, () => fetchOrderShippingPackages(id))

    const {
        isLoading: isLoadingPackingOptions,
        isError: isErrorPackingOptions,
        error: packingOptionsError,
        data: packingOptions
    } = useQuery(['packing_options', data?.store?.id], () => fetchStorePackingOptions(data.store.id), {enabled: !!data?.store})


    useEffect(() => {
        const autofocusSearchInput = () => {
            let focusTimer = null

            if(!inputRef.current){
                focusTimer = setTimeout(() => autofocusSearchInput(), 50)
            }else{
                inputRef.current?.focus()
            }

            return focusTimer
        }

        let focusTimer = null

        if(!isLoading && !openWarningDialog && !openDialogImei && !warehousePackingMaterialsIsLoading && !storePackingMaterialsIsLoading){
            focusTimer = autofocusSearchInput()
        }

        return (() => {
            if(focusTimer){
                return clearTimeout(focusTimer)
            }
        })
    }, [shipment, isLoading, openWarningDialog, openDialogImei, warehousePackingMaterialsIsLoading, storePackingMaterialsIsLoading])
    
    useEffect(() => {
        if(data?.meli_shipment_id && shippingLabel?.label_file){
            let searchParamsLabel = {
                order_id: id,
                carrier_name: "Mercado Envíos",
                status: "VALID"
            }
            getExtraShippingLables(searchParamsLabel)
        }
    }, [shippingLabel])

    useEffect(() => {
    }, [extraShippingLabels])

    useEffect(() => {
        if(isProductionEnvironment){
            dispatch(setRecordingState({
                isRecording: true,
                fileIdentifier: `${id}_${generateRandomId()}`
            }))
        }
    }, [dispatch, id])

    const isPackagelessOrder = useMemo(() => validateIsPackagelessOrder(data?.order_lines), [data])
    const isFragileOrder = useMemo(() => validateIsFragileOrder(data?.order_lines), [data])
    const hasPackagelessProduct = useMemo(() => validateHasPackagelessProduct(data?.order_lines), [data])
    const alreadyHasLabels = useMemo(() => data?.shipping_labels?.length > 0 && (["shipped","returned","returning"].includes(shipment?.status)), [data, shipment])

    const areShippingPackagesValid = useMemo(() => {
        console.log("areShippingPackagesValid", shippingDetails?.packages)
        if(!shippingDetails?.packages || shippingDetails.packages.length <= 0){
            return false
        }

        return shippingDetails.packages.every(shippingPackage => {
            if(!shippingPackage){
                return false
            } else if(!parseInt(shippingPackage.length_cm) || !parseInt(shippingPackage.width_cm) || !parseInt(shippingPackage.height_cm)){
                return false
            } else if(!parseFloat(shippingPackage.weight_kg) || !parseFloat(shippingPackage.volumetric_weight)){
                return false
            }

            return true
        })
    },[shippingDetails])

    const isInternationalOrder = useMemo(() => {
        const warehouseCountry = user.current_warehouse?.country
        return warehouseCountry !== shippingAddress.country.value
    }, [shippingAddress, user.current_warehouse?.country])


    const handleGenerateLabel = async () => {
        LogRocket.track('GenerateLabel', {
            order_number: shipment.order_number,
            store_name: shipment.store_name,
            tote: shipment.tote
        });
        setLoading(false)
        if (data && data.status == 'picking') {
            window.alert("El picking de esta orden no se ha completado")
            return true
        }

        // Validate if the selected shipping method is multi-package
        if(shippingDetails?.packages_count > 1 && shippingDetails?.shippingMethod?.shipping_type !== SHIPPING_TYPES.PICKUP){
            
            const isMultiPackageShippingMethod = shippingDetails?.shippingMethod?.enable_multipackage ? shippingDetails?.shippingMethod?.enable_multipackage : false

            if(!isMultiPackageShippingMethod){
                setErrorMessage("El método de envío seleccionado no permite multi-paquete")
                return
            }
        }

        // Validate if the order is COD and the shipping method is also COD
        if(data?.is_cod && !shippingDetails?.shippingMethod?.is_cod){
            setErrorMessage("El método de envío seleccionado no permite pago contra reembolso")
            return
        }

        if(shippingDetails.shippingMethod.carrier_name === "CORREIOS" && (shippingDetails.shippingMethod.contract_provider === null || shippingDetails.shippingMethod.contract_provider === "CORREIOS_PREPAID")) {
            setOpenDialogCorreios(true)
        } else {
            setOpenConfirmDialog(true)
        }
        setErrorMessage(null)

    }
    const setOrderData = (info) => {
        if (info.shipping.country == '' || info.shipping.country == null) {
            info.shipping.country = "Mexico"
        }
        setPickingOrder(info.picking_order)
        let index = options.findIndex(country => (country.label == info.shipping.country || country.value == info.shipping.country));
        setShippingDocuments([...info.shipping_documents])
        let orderZipcode = info.shipping.zip_code || ""
        orderZipcode = orderZipcode.replace(/[^a-zA-Z0-9 \-]/g, '')
        dispatch(setData({
                shipment: {
                    orderId: info.id,
                    status: info.status,
                    warehouseId: info.warehouse_id,
                    order_number: info.order_number,
                    store_name: info.store.name,
                    store_id: info.store.id,
                    tote: info.picking_order ? info.picking_order.tote.unique_code : "",
                    productsData: []
                },
                pickingOrder: {
                    id: info.picking_order ? info.picking_order.id : null,
                },
                shippingAddress: {
                    status: "editing",
                    firstName: info.shipping.first_name || "",
                    lastName: info.shipping.last_name || "",
                    phone: info.shipping.phone || "",
                    email: info.shipping.email || "",
                    address: info.shipping.address_1 || "",
                    address2: info.shipping.address_2 || "",
                    country: options[index] || {id: "MXN", name: 'MXN'},
                    city: info.shipping.city || "",
                    province: info.shipping.state || "",
                    zipCode: orderZipcode,
                },
                shippingDetails: {
                    shippingMethod: info.shipping_method,
                    shippingDate: moment().format("YYYY-MM-DD"),
                    description: "",
                    packages: [],
                    packages_count: 1
                },
                itmes: info.order_lines
            })
        )

        let productsById = {}
        let nonScannableOrderLines = []
        info.order_lines.forEach(orderLine => {
            if(orderLine.product?.is_scannable) {

                if (orderLine.product?.is_kit && orderLine.product?.kit_childs?.length) {
                    // kit_childs are relationships between kits (parent product) and the kit's children (child product)
                    orderLine.product.kit_childs.forEach(kit_child => {
                        if (!kit_child.child_product?.is_dropshipping) {
                            if(kit_child.child_product.is_scannable) {
                                productsById = increaseProductQuantity(productsById, kit_child.child_product, kit_child.quantity * orderLine.quantity)
                            }
                            else {
                                nonScannableOrderLines.push(orderLine)
                            }
                        }
                    });
                }
                 else {
                    productsById = increaseProductQuantity(productsById, orderLine.product, orderLine.quantity)
                }
            } 
            else {
                nonScannableOrderLines.push(orderLine)
            }
        });
       
        setProductsToReviewById(productsById)
    }

    const increaseProductQuantity = function (productsById, product, quantity = 1) {
        if (!productsById[product.id]) productsById[product.id] = product

        const currentQuantity = productsById[product.id].quantity
        productsById[product.id].quantity = currentQuantity ? currentQuantity + quantity : quantity

        return productsById
    }

    const getExtraShippingLables = async (searchParamsLabel) => {
        try {
            const response = await getShipingLabels(
                searchParamsLabel
            )
            let extra_shipping_labels = response.shipping_labels.filter(shipping_label => shipping_label.id != shippingLabel.id)
            setExtraShippingLabels(extra_shipping_labels)
        } catch (e) {
            setErrorMessage(e?.response?.data?.error)
        }
    }

    const onConfirmGenerateLabelHandler = async () => {
        setConfirmLoading(true)
        try {
            const response = await generateLabel(
                shipment,
                shippingAddress,
                shippingDetails,
                trackingNumberCorreios
            )
            
            setShippingLabel(response.shipping_label)
            await queryClient.invalidateQueries(`order-${id}`)
            setTimeout(() => {
                setOpenConfirmDialog(false)
                setConfirmLoading(false)

                const nonPrintedDocuments = shippingDocumentsToBePrinted();

                if (response.shipping_label)
                    print("Imprimir Guía", response.shipping_label.label_file.path,"label")
                else if(nonPrintedDocuments.length)
                    printShippingDocument(nonPrintedDocuments[0])

                setLoading(false)
            }, 500);
        } catch (e) {
            setErrorMessage(e?.response?.data?.error)
            setOpenConfirmDialog(false)
            setConfirmLoading(false)
            setLoading(false)
            setOpenDialogCorreios(false)
        }
    }

    const goToNextOrderInTote = async () => {
        setConfirmLoading(true)
        try {
            setLoadingButtonLoader(true)
            let new_excluded_picking_order_ids = inhereted_exclude_picking_order_ids.concat(data.picking_order.id)
            let pickingOrder = await fetchSipSiblingByProduct(data.picking_order.id, data.order_lines[0].product.bar_code, new_excluded_picking_order_ids)

            dispatch(stopRecording())

            let query = new URLSearchParams({"exclude_picking_order_ids": new_excluded_picking_order_ids})
            let exclude_picking_order_ids = decodeURIComponent(query)
            history.push({pathname: GENERATE_ORDER_LABEL.replace(":id", pickingOrder.order.id).replace(':warehouse_id', warehouse_id), search: `?${exclude_picking_order_ids}`})
             // goToGenerateLabel(pickingOrder.order.id)
            setLoadingButtonLoader(false)
        } catch (e) {
            if (e.response && e.response.status === 404){
                window.alert("Ya no hay más pedidos pendientes de empacar para esta SKU, en este tote. Regresa al módulo de generar guías y escanea otro tote o producto.")
            }
            setErrorMessage(e.response.data.error)
            setOpenConfirmDialog(false)
            setConfirmLoading(false)
            setLoading(false)
            setOpenDialogCorreios(false)
        }
    }

    const handleClosePreview = () => {
        setTimeout(() => {
            history.push(GENERATE_LABELS.replace(':warehouse_id', warehouse_id))
            //history.goBack()
        }, 500);
    };

    const handleCarreiosSubmit = function (tracking_number) {
        setTrackingNumber(tracking_number)
        //if(hotkey) onConfirmGenerateLabelHandler()
        //else 
        setOpenConfirmDialog(true)
        
    }

    const handleImeiSubmit = function (imei) {
        const allScannedImeis = shipment.productsData.map( productData => productData.imeis ).flat()
        const imeiIsDuplicate = allScannedImeis.includes(imei)

        if (imeiIsDuplicate) {
            setAlert('IMEI duplicado')
            setOpenDialogImei(false)
            return false
        }

        let newProductsData = cloneDeep(shipment.productsData)
        const alreadyScannedProductIndex = newProductsData.findIndex( productData => productData.product_id === reviewedProductId )
        if (alreadyScannedProductIndex === -1) {
            newProductsData.push({
                product_id: reviewedProductId,
                imeis: [imei]
            })
        }
        else {
            newProductsData[alreadyScannedProductIndex].imeis.push(imei)
        }

        dispatch(
            setShipmentData({
                ...shipment,
                productsData: newProductsData
            })
        )

        increaseReviewedItemsCount(reviewedProductId)
        setOpenDialogImei(false)
    }

    const increaseReviewedItemsCount = function (productId) {
        productsToReviewById[productId].reviewedItemsCount++
        // This is necessary to make the object feel the change.
        setProductsToReviewById({...productsToReviewById})
    }

    const handleProductReview = function (product) {
        if (!product.has_imei) {
            increaseReviewedItemsCount(product.id)
        } else {
            setReviewedProductId(product.id)
            setOpenDialogImei(true)
        }
    }

    const productCodeBelongsToProduct = (product, productCode) => product.bar_code == productCode || product.product_codes.some(productCodes =>
        productCodes.sku == productCode || productCodes.upc == productCode
    )

    const addProducts = (productCode) => {
        if (productCode === '' || productCode === null) {
            return
        }
        productCode = productCode.trim()
        setAlert(false)
        const reviewedProduct = Object.values(productsToReviewById).find(product => {
            if (product.reviewedItemsCount == null) product.reviewedItemsCount = 0

            const hasSurplus = product.reviewedItemsCount > product.quantity

            if (hasSurplus) setAlert("Hay un excedente de este producto.")

            const isReviewCompleted = product.reviewedItemsCount == product.quantity

            if (isReviewCompleted || hasSurplus) return false

            return productCodeBelongsToProduct(product, productCode)

        })

        if (reviewedProduct) {
            handleProductReview(reviewedProduct)
        } else {
            setOpenWarningDialog(true)
            // setAlert("Este producto no pertenece a la orden o ya ha sido revisado.")
        }

        setLatestAddedProduct(reviewedProduct)

        inputRef.current.resetInput()
        inputRef.current.setIsSearchDisabled(false)
        inputRef.current.focus()
    }
    const trackingNumberCorreios = useMemo(() => {
        if (trackingNumber) {
            return trackingNumber
        }
        else return null
    }, [trackingNumber])

    const validQualityCheck = useMemo(() => {
        let totalItemsToReview = 0
        let totalReviewedItems = 0

        Object.values(productsToReviewById).forEach(product => {
            totalItemsToReview += product.quantity
            totalReviewedItems += product.reviewedItemsCount || 0
        });
        return totalItemsToReview == totalReviewedItems
    }, [productsToReviewById])

    const userValid = useMemo(() => {
        return user.permissions.generate_additional_labels
    }, [user])

    const validateShippingAddressForm = useMemo(() => {

        if (shippingDetails.shippingMethod?.shipping_type != "pickup" && (shippingAddress.zipCode == "" || shippingAddress.city == "" || shippingAddress.province == "" || shippingAddress.country == "" || shippingAddress.first_name == "" || shippingAddress.address1 == "" )) {
            setErrorMessage("Falta información en la orden")
            return false
        }
        // if (shippingDetails.shippingMethod?.carrier_name == "DHL" && shippingAddress.phone == "") {
        //     setErrorMessage("Falta información en la orden")
        //     return false
        // }
        
        return true
    }, [shippingAddress, shippingDetails])

    const enforcePackagesCountLimit = (newValue, previousValue) => newValue > 0 && newValue <= 50 ? newValue : previousValue

    const ValidShippingLabels = useMemo(() => {
        setErrorMessage(null)

        if (data?.shipping_labels.length) return true
        return false
    }, [data])
    const formValid = useMemo(() => {
        console.log("formValid")
        if(busyPackageSelection) {
            console.log("busyPackageSelection", busyPackageSelection)
            return false
        }
        if(!areShippingPackagesValid){
            console.log("areShippingPackagesValid", areShippingPackagesValid)
            return false
        }

        if (validateShippingAddressForm) {
            if (!userValid){
                console.log("unpriviledged user, qc required")
                if (ValidShippingLabels) {
                    setErrorMessage("No puede generar más guías para esta orden, por favor avise al equipo de operaciones para realizar esta acción")
                }
                console.log("validQualityCheck", validQualityCheck)
                return validQualityCheck && !ValidShippingLabels
            } 
            else return userValid
        }
        return false
    }, [validQualityCheck, validateShippingAddressForm, userValid, ValidShippingLabels, areShippingPackagesValid, busyPackageSelection])

    const print = (title,path, type) => {
        setOpenDialogDocument(false)
        setPrintInformation({
            ...printInformation,
            "title": title, 
            "type": type,
            "file_path": path
        })
        setOpenDialogDocument(true)
    }

    const shippingDocumentsToBePrinted = () => shippingDocuments.filter(shippingDocument => !shippingDocument.print)
    const shippingLabelsToBePrinted = () => extraShippingLabels.filter(shippingLabel => !shippingLabel.print)

    const printShippingDocument = function (documentToPrint) {
        const shipping_documents = shippingDocuments.map(sd => (sd.id === documentToPrint.id ? {...sd, print:true} : sd))
        setShippingDocuments([...shipping_documents])

        print("Imprimir Documentos de Envío", documentToPrint.file.path, documentToPrint.printing_type)
    }

    const printExtraShippingLabels = function (labelToPrint) {
        const shipping_labels = extraShippingLabels.map(sl => (sl.id === labelToPrint.id ? {...sl, print:true} : sl))
        setExtraShippingLabels([...shipping_labels])

        print("Imprimir Etiquetas Extras", labelToPrint.label_file.path, "label")
    }

    useHotkeys('ctrl+g', () => {
        //handleGenerateLabel()
        if (!formValid) {
            setLoading(false)
            return
        } else {
            setLoading(true)
            if(shippingDetails.shippingMethod.carrier_name === "CORREIOS" && (shippingDetails.shippingMethod.contract_provider === null || shippingDetails.shippingMethod.contract_provider === "CORREIOS_PREPAID")) {
                setOpenDialogCorreios(true)
            }
            else onConfirmGenerateLabelHandler()
        }
    }, {enableOnTags: ["INPUT", 'TEXTAREA', 'SELECT']}, [formValid]);

    return (
        <>
            {(!isPreviousData && !warehousePackingMaterialsIsPreviousData && !storePackingMaterialsIsPreviousData) && (isLoading || isLoadingImeiValidationRegex || isLoadingPackingOptions || warehousePackingMaterialsIsLoading || storePackingMaterialsIsLoading) ? (
                <><Loader show={true}></Loader></>
            ) : isError || isErrorImeiValidationRegex || isErrorPackingOptions || warehousePackingMaterialsIsError || storePackingMaterialsIsError ? (
                <>Errors: {error.message || ""} {ImeiValidationRegexError.message || ""} {packingOptionsError.message || ""} {warehousePackingMaterialsError.message || ""} {storePackingMaterialsError.message || ""}</>
            ) : (
                <PageView
                    topMenu={
                        <PageTopBar>
                            <div className="text-lg font-semibold">
                                {userValid && (
                                    <>
                                        <span className="text-gray-400"> Generar Guía  &rarr; </span>
                                        Orden: #{shipment.order_number}

                                        <span className="text-gray-400 ml-10"> Tienda &rarr; </span>
                                        {shipment.store_name}
                                    </>
                                )}

                                <span className="text-gray-400 ml-10"> Tote: &rarr; </span>
                                {shipment.tote}
                            </div>

                            {
                                data.picking_order && data.picking_order.single_item_picking ? 
                                <>
                                    <ButtonLoader type="secondary" loading={loadingButtonLoader} onClick={() => {
                                        goToNextOrderInTote()
                                    }}>
                                        Ir a la siguiente orden para este producto
                                    </ButtonLoader>
                                </> 
                                : 
                                <></>
                            }
                        </PageTopBar>
                    }
                    childrenFullWidth={true}
                    topMenuFullWidth={true}
                    bottomMenuStyles={"border-t-2"}
                    errorMessage={errorMessage}
                    bottomMenu={
                        <GenerateShipmentFooter
                            onSave={handleGenerateLabel}
                            onCancel={() => history.goBack()}
                            isSaveDisabled={!formValid}
                            primaryButtonLabel={alreadyHasLabels ? "Invalidar Guías Anteriores y Generar Guía" : "Generar Guía"}
                        />
                    }
                >
                    {(loading || busyPackageSelection) &&
                        <><Loader show={true}></Loader></>
                    }

                    
                    <ConfirmDialog
                        open={openConfirmDialog}
                        setOpen={setOpenConfirmDialog}
                        title={alreadyHasLabels ? "Invalidar Guías Anteriores y Generar Guía" : "Generar Guía"}
                        description={alreadyHasLabels ? "Se invalidarán todas las guías anteriores y se generarán nuevas guías. ¿Confirmas Proceder?" : "¿Deseas generar esta guía?"}
                        confirmLabel="Si"
                        onConfirm={() => {
                            onConfirmGenerateLabelHandler()
                        }}
                        loading={confirmLoading}
                    />

                    <ConfirmDialog
                        open={openWarningDialog}
                        setOpen={() => {
                            setOpenWarningDialog(false)
                        }}
                        title="¡Cuidado!"
                        description={`Este producto no pertenece a la orden o ya ha sido revisado.`}
                        confirmLabel="Aceptar"
                        cancelLabel="Cancelar"
                    />


                    <DialogView setOpen={setOpenDialogDocument} open={openDialogDocument}>
                        <LabelView

                            title={printInformation.title}
                            type={printInformation.type}
                            copies = {printInformation.type == 'identifier_label' ? shippingDetails.packages_count : 1}
                            file_path={printInformation.file_path}
                            onCancel={() =>{ 
                                if (printInformation.type == 'document') 
                                    setOpenDialogDocument(false)
                                else 
                                    handleClosePreview(false)
                                }}
                                
                            onPrint={() => {
                                setOpenDialogDocument(false)

                                const nonPrintedDocuments = shippingDocumentsToBePrinted();
                                const nonPrintedLabels = shippingLabelsToBePrinted();
                                if(nonPrintedLabels.length){
                                    printExtraShippingLabels(nonPrintedLabels[0])
                                }else if (shippingDetails.shippingMethod.shipping_type == "international" && shippingLabel.billing_file && !shippingLabel.billing_file.print) {
                                    shippingLabel.billing_file.print = true
                                    print("Imprimir Factura Comercial",shippingLabel.billing_file.path, "billing"  )
                                } else if(nonPrintedDocuments.length) {
                                    printShippingDocument(nonPrintedDocuments[0])
                                }else if(shippingLabel.invoice_label_file && shippingLabel.invoice_label_file.path && !shippingLabel.invoice_label_file.print){
                                    shippingLabel.invoice_label_file.print = true
                                    print("Imprimir Factura Brasil",shippingLabel.invoice_label_file.path, "label"  )
                                }else if(!shippingLabel.billing_file && data.shipping_labels[0].billing_file && !data.shipping_labels[0].billing_file.print ){
                                    data.shipping_labels[0].billing_file.print = true
                                    print("Imprimir Factura Comercial",data.shipping_labels[0].billing_file.path, "billing")
                                }else {
                                    handleClosePreview(false)
                                }
                               
                            }
                            }
                        />
                    </DialogView>

                    <PickingOrderToReviewDialog
                        setOpen={setOpenDialogRevertPacking}
                        open={openDialogRevertPacking}
                        onCancel={() => setOpenDialogRevertPacking(false)}
                        onSuccess={() => {history.goBack()}}
                        tote={shipment.tote}
                        pickingOrderId={pickingOrder?.id}
                        productsToReviewById={productsToReviewById}
                    />
                    <ImeiDialog
                        setOpen={setOpenDialogImei}
                        open={openDialogImei}
                        onCancel={() => setOpenDialogImei(false)}
                        onSubmit={imei => handleImeiSubmit(imei)}
                        imeiValidationRegex={imeiValidationRegex}
                    />
                    <CorreiosDialog
                        setOpen={setOpenDialogCorreios}
                        open={openDialogCorreios}
                        onCancel={() => setOpenDialogCorreios(false)}
                        onSubmit={tracking_number => handleCarreiosSubmit(tracking_number)}
                    />
                    <div className="flex-1 flex items-stretch gap-4">
                        <main className="flex-1 flex flex-col gap-4">

                            <ShippingAddressSection
                                shippingAddress={shippingAddress}
                                countyOptions={options}
                                onShippingAddressChange={updatedShippingAddress => dispatch( setShippingAddress(updatedShippingAddress) )}
                                isHidden={!user?.permissions?.wms_show_shipping_address}
                                isEditDisabled={!user?.permissions?.wms_edit_shipping_address}
                            />

                            <ProductListSection
                                products={productsToReviewById}
                                productSearchRef={inputRef}
                                onSearchChange={value => addProducts(value)}
                                productSearchDependencies={productsToReviewById}
                                productSearchAlertMsg={alert}
                                latestAddedProduct={latestAddedProduct}
                                className={'mt-5'}
                            />

                            <InsertsSection
                                orderLines={data?.order_lines}
                                className={'mt-5'}
                            />

                            <ShippingLabelsListSection
                                shippingLabels={data?.shipping_labels}
                                meliShipmentId={data?.meli_shipment_id}
                                onPrintLabel={(labelFile) =>print("Imprimir Guía", labelFile.path,"label")}
                                isHidden={ !alreadyHasLabels }
                                className={'mt-5'}
                            />

                            <AttachmentsListSection
                                shippingDocuments={shippingDocuments}
                                onPrintDocument={(documentFile) => print("Imprimir Documentos de Envío", documentFile.path,"document")}
                                isHidden={ !(shippingDocuments?.length > 0 && shipment?.status === "shipped") }
                            />
                        </main>

                        <aside className="w-5/12 border-gray-200 flex flex-col gap-4">

                            <MissingPickingPiecesSection
                                isHidden={!user.permissions?.put_picking_order_in_review}
                                onSubmitOrderForReview={() => setOpenDialogRevertPacking(true)}
                                isSubmitOrderForReviewDisabled={!formValid}
                            />

                            <MissingInfoNotificationSection
                                isMissingInfo={!validateShippingAddressForm}
                            />

                            <GeneratedLabelNotificationSection
                                isShippingLabelGenerated={alreadyHasLabels}
                            />

                            <FragileNotificationSection
                                isFragileOrder={isFragileOrder}
                                warehousePackingMaterials={warehousePackingMaterialsData?.warehouse_packing_materials}
                                storePackingMaterials={storePackingMaterialsData?.store_packing_materials}
                            />

                            <CodNotificationSection
                                isCodOrder={data?.is_cod}
                                totalOrderPrice={data?.total_price}
                                currency={data?.currency}
                            />

                            <RecommendedPackageSection
                                availablePackageOptions={packingOptions?.store_packing_options}
                                isPackagelessOrder={isPackagelessOrder}
                                isFragileOrder={isFragileOrder}
                            />

                            <ShippingPackageDataSection
                                onPackageNumberChange={(newPackagesCount, newShippingPackages) => {
                                    // console.log("onPackageNumberChange", newPackagesCount, newShippingPackages)
                                    dispatch(
                                        setShippingDetails({
                                            ...shippingDetails,
                                            packages: [...newShippingPackages],
                                            packages_count: enforcePackagesCountLimit(newPackagesCount, shippingDetails.packages_count)
                                        })
                                    )
                                }}
                                onShippingMethodChange={(newShippingMethod, newPackageQuantity, newShippingPackages) => {
                                    console.log("onShippingMethodChange", newShippingMethod, newPackageQuantity, newShippingPackages)
                                    // console.log("onShippingMethodChange shippingDetails", shippingDetails)
                                    dispatch(
                                        setShippingDetails({
                                            ...shippingDetails,
                                            shippingMethod: newShippingMethod,
                                            packages: newShippingPackages !== undefined ? [...newShippingPackages] : shippingDetails.packages,
                                            packages_count: newPackageQuantity !== undefined && newPackageQuantity !== null ? enforcePackagesCountLimit(newPackageQuantity, shippingDetails.packages_count) : shippingDetails.packages_count
                                        })
                                    )
                                }}
                                onShippingPackageChange={(newShippingPackages) => {
                                    // console.log("onShippingPackageChange", newShippingPackages)
                                    dispatch(
                                        setShippingDetails({
                                            ...shippingDetails,
                                            packages: [...newShippingPackages]
                                        })
                                    )
                                }}
                                initialShippingMethod={data?.shipping_method}
                                availableShippingMethods={cubboShippingMethods?.data}
                                availableShippingPackages={shippingPackages?.data}
                                storePackingOptions={packingOptions?.store_packing_options}
                                orderWarehouseId={data?.warehouse_id}
                                orderStoreId={data?.store?.id}
                                isPackagelessOrder={isPackagelessOrder}
                                hasPackagelessProduct={hasPackagelessProduct}
                                isFragileOrder={isFragileOrder}
                                isShippingMethodEditable={user?.permissions?.wms_edit_shipping_method}
                                shippingMethodOptions={cubboShippingMethods?.data}
                                disableMultiPackageAutoSelect={data?.is_cod || isInternationalOrder}
                                orderCanRecalculateShippingMethod={data?.channel_name && data?.channel_name !== 'manual'}
                                onBusyChange={(isBusy) => setBusyPackageSelection(isBusy)}
                                orderId={data?.id}
                            />

                            <InternationalNotificationSection
                                isInternationalOrder={isInternationalOrder}
                            />

                        </aside>
                    </div>
                </PageView>
            )}
        </>
    )
}